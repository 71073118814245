/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$screen-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

@each $size, $size-value in $screen-breakpoints {
    [hide-#{$size}] {
      @media (min-width: $size-value) {
        display: none;
      }
    }
}

@each $size, $size-value in $screen-breakpoints {
    [show-#{$size}] {
      @media (max-width: #{$size-value - 1}) {
        display: none;
      }
    }
}

/* ion button: #3880ff */
.mat-raised-button.mat-primary {
    background-color: #3880ff;
}
.mat-button.mat-primary {
    color: #3880ff;
}

.mat-tab-label {
    padding: 0px 4px 0px 4px !important;
}
@media (max-width: 599px) {
    .mat-tab-label {
        min-width: 48px !important;
    }
}
@media (min-width: 600px) and (max-width: 959px) {
    .mat-tab-label {
        min-width: 72px !important;
    }
}

body.scanner-active {
    --background: transparent;
    --ion-background-color: transparent;
}

.inputField {
    background-color: rgba(var(--ion-color-primary-rgb), 0.14);
}

table.taglist {
    padding: 1px;
}

table.taglist > tr > td {
    vertical-align: top;
    padding: 4px;
}

@media (min-width: 768px) {
    table.taglist > tr > td.mobile {
        display: none;
    }
}
@media (max-width: 768px) {
    table.taglist > tr > td.tag {
        display: none;
    }
    table.taglist > tr > td.value {
        display: none;
    }
}


.tag {
    font-size: small;
    color: gray;
}

[hidden] {
    display: none !important;
}
